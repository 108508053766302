import React, {FormEvent, ReactNode, useState} from "react";
import {CONTACT_EMAIL, MESSAGE, NOM_PRENOM, PHONE_NUMBER, sendMailContactFromForm, SUBJECT} from "../lib/emailUtils";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {Alert} from "./toast";

export const FormContact = ({isLarge, currentPage}: { isLarge: boolean, currentPage: string }) => {
    const [toastState, setToastState] = useState({
        show: false,
        title: "",
        content: ""
    });

    const onSuccess = () => {
        setToastState({
            show: true,
            title: "Le formulaire a bien été envoyé !",
            content: "Nous vous remercions pour votre prise de contact et nous reviendrons vers vous, dans les plus brefs délais."
        });
    }

    const onFail = () => {
        setToastState({
            show: true,
            title: "Une erreur est survenue...",
            content: "Nous vous invitons à réitérer votre prise de contact."
        });
    }

    const closeToast = () => {
        setToastState({
            show: false,
            title: "",
            content: ""
        })
    }

    const onSubmit = (event: FormEvent<HTMLFormElement>) => sendMailContactFromForm(event, currentPage, onSuccess, onFail);

    return (
        <>
            <div
                className={"flex flex-col items-center gap-3 w-full"}>
                <div className={"text-center"}>
                    <h3 className={"text-2xl font-MADE text-white"}>Nous ContaCter</h3>
                </div>
                <div className={"bg-white h-1 w-20 rounded-full"}></div>
                <div className={"w-full flex flex-col gap-3 text-justify"}>
                    <Form
                        className={"flex flex-col justify-center items-center w-full gap-3"}
                        onSubmit={onSubmit}
                    >
                        {isLarge
                            ? (
                                <>
                                    <ContentLarge>
                                        <SubjectForm/>
                                        <NameForm/>
                                    </ContentLarge>
                                    <ContentLarge>
                                        <EmailForm/>
                                        <PhoneNumberForm/>
                                    </ContentLarge>
                                </>
                            ) : (
                                <>
                                    <SubjectForm/>
                                    <NameForm/>
                                    <EmailForm/>
                                    <PhoneNumberForm/>
                                </>
                            )
                        }
                        <MessageForm/>
                        <Button
                            type="submit"
                            className="text-white font-medium rounded-2 bg-white/25 border-none hover:border-none hover:bg-white/40">
                            Envoyer
                        </Button>
                    </Form>
                </div>
            </div>
            {toastState.show && <Alert content={toastState.content} title={toastState.title} onCloseCalled={closeToast}/>}
        </>
    );
}

const ContentLarge = ({children}: { children: ReactNode }) => {
    return (
        <div className={"w-full xl:inline-flex lg:inline-flex md:flex sm:flex gap-3"}>
            {children}
        </div>
    );
}

const FormGeneric = ({controleId, label, children}: { controleId: string; label: string; children: ReactNode }) => {
    return (
        <Form.Group className="w-full flex flex-col" controlId={controleId}>
            <Form.Label className={"font-semibold text-white"}>{label}</Form.Label>
            {children}
        </Form.Group>
    );
}

const SubjectForm = () => {
    return (
        <FormGeneric controleId={SUBJECT} label={"Sujet *"}>
            <Form.Control
                type="text"
                name={SUBJECT}
                className={"focus-visible:ring-1 focus-visible:border-white/25"}
                placeholder={"Sujet"}
            />
        </FormGeneric>
    );
}

const NameForm = () => {
    return (
        <FormGeneric controleId={NOM_PRENOM} label={"Nom & Prénom *"}>
            <Form.Control
                type="text"
                name={NOM_PRENOM}
                className={"focus-visible:ring-1 focus-visible:border-white/25"}
                placeholder={"Nom & Prénom"}
            />
        </FormGeneric>
    );
}

const EmailForm = () => {
    return (
        <FormGeneric controleId={CONTACT_EMAIL} label={"Email *"}>
            <Form.Control
                type="email"
                name={CONTACT_EMAIL}
                className={"focus-visible:ring-1 focus-visible:border-white/25"}
                placeholder={"mon@adresse-email.com"}
            />
        </FormGeneric>
    );
}

const PhoneNumberForm = () => {
    return (
        <FormGeneric controleId={PHONE_NUMBER} label={"Téléphone *"}>
            <Form.Control
                type="tel"
                name={PHONE_NUMBER}
                className={"focus-visible:ring-1 focus-visible:border-white/25"}
                placeholder={"00 00 00 00 00"}
            />
        </FormGeneric>
    );
}

const MessageForm = () => {
    return (
        <FormGeneric controleId={MESSAGE} label={"Message *"}>
            <Form.Control
                as="textarea"
                rows={5}
                name={MESSAGE}
                className={"focus-visible:ring-1 focus-visible:border-white/25"}
                placeholder={"Bonjour,"}
            />
        </FormGeneric>
    );
}