import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import './index.css';
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider,} from 'react-router-dom';
import NoMath from "./pages/NoFoundOrError/NoMatch";
import MainLayout from "./Layout/MainLayout";
import ErrorPage from "./pages/NoFoundOrError/ErrorPage";
import Home from "./pages/Home";
import Guarantees from "./pages/Guarantees";
import {Contact} from "./pages/Contact";
import AboutUs from "./pages/AboutUs";
import {Program, Programs} from "./pages/Programs";


export default function App() {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route
                path={"/"}
                element={<MainLayout/>}
                errorElement={<ErrorPage/>}
            >
                <Route path={"/"} handle="Accueil" element={<Home/>}/>
                <Route
                    caseSensitive={true}
                    path={"nos-garanties"}
                    element={<Guarantees/>}/>
                <Route
                    caseSensitive={true}
                    path={"contact"}
                    element={<Contact/>}/>
                <Route
                    caseSensitive={true}
                    path={"nos-programmes"}
                    element={<Programs/>}/>
                <Route
                    caseSensitive={true}
                    path="nos-programmes/:program"
                    handle={{
                        paramName: 'program'
                    }}
                    element={<Program/>}
                />
                <Route
                    caseSensitive={true}
                    path={"presentation"}
                    element={<AboutUs/>}/>
                <Route path={"*"} element={<NoMath/>}/>
            </Route>
        )
    );

    return (
        <RouterProvider
            router={router}
        />
    );
};
