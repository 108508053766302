import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {MailIcon} from "lucide-react";
import Hamburger from 'hamburger-react';
import {Link, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {cn} from "../lib/utils";

export function Navigation() {
    const location = useLocation();

    const scrollPosition = useScrollPosition();

    const isTopScroll = scrollPosition > 0 || location.pathname !== "/";

    const [isOpen, setOpen] = useState(false);

    return (
        <header className={"w-full"}>
            {/*Version ORDINATEUR*/}
            <Navbar collapseOnSelect={true} data-bs-theme={"dark"} fixed={'top'} expand={'lg'}
                    className={cn({
                        "p-0 transition-all duration-1000 z-[1100]" : true,
                        "bg-[#022855]": isTopScroll
                    })}
            >
                <Navbar.Brand
                    as={Link}
                    to="/"
                    className={cn({
                        "transition-all duration-1000 flex-grow-1 text-white lg:text-2xl md:text-xl text-lg p-2 h-full flex items-center font-MADE break-keep": true,
                        "invisible": !isTopScroll})
                    }>
                        horizon grand large
                </Navbar.Brand>
                <Nav className={cn({
                    "transition-all duration-1000 gap-3 h-full justify-center items-center flex-nowrap lg:flex hidden": true})}
                >
                    <Nav.Link as={Link} className={'h-full flex items-center'}
                              to={"presentation"}
                              active={location.pathname === `/presentation`}>Présentation</Nav.Link>
                    <Nav.Link as={Link} className={'h-full flex items-center'}
                              to={"nos-programmes"}
                              active={location.pathname === `/nos-programmes`}>Nos programmes</Nav.Link>
                    <Nav.Link className={'h-full flex items-center'} as={Link} to={"nos-garanties"}
                              active={location.pathname === `/nos-garanties`}>Nos
                        garanties</Nav.Link>
                    {/*<Nav.Link className={'h-full flex items-center'} as={Link} to={"nos-actualites"} active={location.pathname === `/nos-actualites`}>Nos*/}
                    {/*    actualités</Nav.Link>*/}
                    <Nav.Link className={'h-full flex items-center'}
                              as={Link} to={"contact"}
                              active={location.pathname === `/contact`}><MailIcon/></Nav.Link>
                </Nav>
                <div className={"z-[1100] lg:hidden flex"}>
                    <Hamburger duration={0.8} color={"white"} toggled={isOpen} toggle={() => {setOpen(!isOpen); window.scrollTo(0, 0)}} />
                </div>
            </Navbar>
            {/*VERSION TELEPHONE*/}
            {isOpen && (
                <div
                    className="z-[1050] absolute bg-[#022855] flex items-center justify-center w-[100vw] h-[100vh] transition-all duration-1000">
                    <div>
                        <Nav className={"grid grid-cols-1 gap-4 h-full text-center text-2xl"}>
                            <Nav.Link
                                as={Link}
                                className={'h-full text-center text-white'}
                                to={"presentation"}
                                active={location.pathname === `/presentation`}
                                onClick={()=>setOpen(false)}
                            >
                                Présentation
                            </Nav.Link>
                            <Nav.Link
                                as={Link}
                                className={'h-full text-center text-white'}
                                to={"nos-programmes"}
                                active={location.pathname === `/nos-programmes`}
                                onClick={()=>setOpen(false)}
                            >
                                Nos programmes
                            </Nav.Link>
                            <Nav.Link
                                className={'h-full text-center text-white'}
                                as={Link} to={"nos-garanties"}
                                active={location.pathname === `/nos-garanties`}
                                onClick={()=>setOpen(false)}
                            >
                                Nos garanties
                            </Nav.Link>
                            {/*<Nav.Link className={'h-full flex items-center'} as={Link} to={"nos-actualites"} active={location.pathname === `/nos-actualites`}>Nos*/}
                            {/*    actualités</Nav.Link>*/}
                            <Nav.Link
                                className={'h-full flex justify-center text-white'}
                                as={Link}
                                to={"contact"}
                                active={location.pathname === `/contact`}
                                onClick={()=>setOpen(false)}
                            >
                                <MailIcon/>
                            </Nav.Link>
                        </Nav>
                    </div>
                </div>
            )}
        </header>
    );
}

export const useScrollPosition = () => {
    const [scrollPosition, setScrollPosition] = useState(0)

    useEffect(() => {
        const updatePosition = () => {
            setScrollPosition(window.pageYOffset)
        }

        window.addEventListener('scroll', updatePosition);

        updatePosition();

        return () => window.removeEventListener('scroll', updatePosition)
    }, [])

    return scrollPosition;
}



