export function Footer() {
    return (
        <footer className="w-full bg-[#022855] bottom-0" role="banner">
                <div className="container">
                    <div className="p-4 flex justify-center items-center">
                        <div>
                            <h3 className="mb-0 text-white">© Groupe IMWEST</h3>
                        </div>
                    </div>
                </div>
        </footer>
        )
    ;
}



