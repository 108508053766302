import React from "react";

export function ContentAboutUs() {
    return (
        <div
            className="flex flex-col items-center gap-5 w-full h-full pt-10 ">
            <div className="flex flex-col items-start gap-4 w-full pt-20">
                <h2 className={"p-1 font-semibold lg:text-5xl md:text-4xl text-3xl text-center font-MADE"}>Présentation</h2>
                <div className={"bg-[#022855] h-1 w-52 rounded-full"}></div>
            </div>
            <div
                className="flex flex-col gap-5 md:w-[80vw] sm:w-[90vw]">
                <div className={"w-full flex flex-col gap-3 text-center"}>
                    <span>
                        La société Horizon Grand Large, créée par Ludwig NORBERT il y a plus de 15 ans, a la volonté d’offrir le meilleur pour chacun de ses programmes. C’est pourquoi nous apportons une extrême exigence concernant 3 points qui sont les piliers d’une résidence de standing réussie :
                    </span>
                </div>
                <div className={"grid grid-cols-1 md:grid-cols-3 gap-3"}>
                    <div className={"flex flex-col gap-3"}>
                        <h3 className={"font-semibold text-2xl text-[#022855] text-left font-MADE"}>Un emplaCement
                            d’exCeption</h3>
                        <div className={"text-justify"}>
                            <span>Nous apportons grand soin au choix des emplacements de nos futures résidences. Ce critère est primordial et doit constituer la valeur ajoutée de votre futures acquisition, en plus de vous faire rêver et de vous inspirer.</span>
                        </div>
                    </div>
                    <div className={"flex flex-col gap-3 "}>
                        <h3 className={"font-semibold text-2xl text-[#022855] text-left font-MADE"}>Une conception
                            d’excellence</h3>
                        <div className={" flex flex-col text-justify gap-1"}>
                            <span>Au cours de ces dernières années, Horizon Grand Large a collaboré avec de nombreux Cabinets d'Architecture à la réputation bien établie.</span>
                            <span>Chaque programme est conçu en osmose avec son environnement, de sorte qu’il s’intégrera parfaitement au paysage qui l’entoure. Notre philosophie est à l'opposé des grands groupes nationaux dont les maîtres mots sont « bilan financier, volume, rendement... ». Nous refusons les produits formatés, banalisés, sans identité. Chaque projet étant unique  à nos yeux, il est étudié avec une attention particulière qui permet de lui donner sa spécificité. </span>
                            <span>Nous recherchons la meilleure exposition pour que la lumière inonde vos journées. Nous concevons des espaces généreux et fonctionnels pour optimiser votre futur lieu de vie.</span>
                        </div>
                    </div>
                    <div className={"flex flex-col gap-3"}>
                        <h3 className={"font-semibold text-2xl text-[#022855] text-left font-MADE"}>Une réalisation s’inscrivant
                            dans un
                            développement
                            durable </h3>
                        <div className={"text-justify"}>
                            <span>Conscient de l'empreinte que nous laissons derrière nous, particulièrement en matière de construction, toutes nos réalisations intègrent une démarche de développement durable et d'économie d'énergies. Les réalisations Horizon Grand Large dépassent bien souvent les exigences des normes actuelles pour apporter, par le choix d'équipements performants, un confort de vie optimal aux futurs résidents. Ce confort s'accompagne d'économies substantielles en terme de consommation énergétique. </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}