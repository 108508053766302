import React from "react";
import {ChevronDownCircle, MapPinIcon} from "lucide-react";
import {MyMapGoogle} from "../../Components/map";
import {PreviewLTP} from "../Programs/Program/ltp";
import {PreviewLTL} from "../Programs/Program/ltl";
import {PreviewLQI} from "../Programs/Program/lqi";
import {PreviewTyCampus} from "../Programs/Program/ty_campus";
import {PreviewJK} from "../Programs/Program/jk";
import {PreviewAlicia} from "../Programs/Program/alicia";
import {BackgroundMultipleImg} from "../../Components/background";
import {useScrollPosition} from "../../Components/menu";
import Slider from "react-slick";
import {cn} from "../../lib/utils";

export function CarrouselImg() {
    const scrollPosition = useScrollPosition();

    const isTopScroll = scrollPosition > 0;
    return (
        <>
            <div className={"z-[1020] h-screen flex flex-col items-center justify-between align-middle p-4"}>
                <div className={"flex h-100 justify-center items-center"}>
                    <div
                        className={"md:lg:max-h-[300px] lg:max-h-[400px] bg-white flex items-end bg-opacity-75 max-w-[90vw] sm:max-w-[75vw] md:max-w-[60vw] lg:max-w-[55vw] justify-center transition-all duration-1000"}>
                        <div className={"h-full max-w-full flex flex-col items-center justify-end"}>
                            <div className={cn({
                                    "flex justify-center items-center scroll ": true,
                                    "hidden": isTopScroll})}
                            >
                                <img src={"../../HGL.png"} alt={""}
                                     className={"w-[70%] sm:w-[60%] md:w-[50%] lg:w-[40%]"}/>
                            </div>
                            <div className={"m-4 text-center flex flex-col gap-3"}>
                                <span className={"text-sm"}>
                                    Horizon Grand Large est une société de promotion immobilière haut de gamme.
                                </span>
                                <span className={"text-sm"}>
                                    Nous sommes conscient que chaque détail compte lors de l'investissement d'un bien.
                                    Pour un confort de vie optimal, nous vous apportons toute notre attention,
                                    notre expertise et notre expérience, afin que vos réalisations soient
                                    conçues avec le goût de l'excellence.
                                </span>
                                <span className={"font-semibold"}>
                                        Notre philosophie
                                    </span>
                                <div>
                                    <span className={"font-bold"}> « </span>
                                    <span className={"text-sm"}>Rechercher le meilleur à chaque étape de la réalisation de nos programmes neufs.</span>
                                    <span className={"font-bold"}> » </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {!isTopScroll && <ChevronDownCircle size={40} className={"text-white z-[1020] animate-bounce"} />}
            </div>
            <BackgroundMultipleImg/>
        </>
    )
}

export function NewCarouselSpacing() {
    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    return (
        <div className="slider-container w-full bg-[#022855] p-10">
            <Slider {...settings}>
                    <PreviewLTP detailHover={false}/>
                    <PreviewAlicia detailHover={false}/>
                    <PreviewJK detailHover={false}/>
                    <PreviewLQI detailHover={false}/>
                    <PreviewTyCampus detailHover={false}/>
                    <PreviewLTL detailHover={false}/>
            </Slider>
        </div>
    );
}

export function ComposantProject(
    {
        title, localisation, logo, url, statut
    }: {
        title: string, localisation: string, logo: string, url?: string, statut?: string
    }
) {
    return (
        <>
            <div className={"m-4"}>
                <img src={logo} alt={""} width={200} height={200} className={"h-[200px] w-[200px] object-cover"}/>
            </div>
            <div>
                {statut && (
                    <span className={"text-xs font-semibold text-[#022855] uppercase"}>{statut}</span>
                )}
            </div>
            <div className={"m-4"}>
                <h1 className={"text-xl font-primary"}>{title}</h1>
            </div>
            <div className={"flex flex-row items-center"}>
                <MapPinIcon className={"h-4 w-4"}/>
                <span>{localisation}</span>
            </div>
            {url && (
                <div>
                    <a href={url} target={"_blank"} rel="noreferrer">
                        <button className={"bg-[#022855] px-4 py-2 text-white rounded-md hover:bg-[#283b57]/90"}>En
                            savoir plus
                        </button>
                    </a>
                </div>
            )}
        </>
    );
}

export function Contact() {
    return (
        <div className={"h-full flex flex-col items-center justify-between"}>
            <div
                className={"bg-[#022855] h-full w-full bg-opacity-75 align-middle p-5 justify-center items-center"}>
                <div className={"max-w-full flex flex-col justify-center items-center text-white"}>
                    <div className={"m-4 text-center"}>
                        <h2 className={"text-3xl font-MADE font-semibold"}>Nous ContaCter</h2>
                    </div>
                    <div className={"m-4 flex justify-evenly flex-wrap w-[80vw]"}>
                        <div className={"flex flex-col p-4 items-center"}>
                            <h3 className={"font-primary text-lg"}>Adresse des bureaux</h3>
                            <span className={"text-sm"}>5 Rue Paul Bert</span>
                            <span className={"text-sm"}>56100 LORIENT</span>
                        </div>
                        <div className={"flex flex-col p-4 items-center"}>
                            <h3 className={"font-primary text-lg"}>Contact</h3>
                            <span className={"text-sm"}>02 97 11 92 69</span>
                            <span className={"text-sm"}>contact@groupe-imwest.com</span>
                        </div>
                        <div className={"flex flex-col p-4 items-center"}>
                            <h3 className={"font-primary text-lg"}>Horaires</h3>
                            <span className={"text-sm"}>Du Lundi au Vendredi</span>
                            <span className={"text-sm"}>9h - 12h / 14h - 17h</span>
                        </div>
                    </div>
                    <div className={"w-[80vw] flex justify-center items-center align-middle"}>
                        <MyMapGoogle/>
                    </div>
                </div>
            </div>
        </div>
    );
}