import {Projects, TitleProjects} from "./components";
import React from "react";
import {Footer} from "../../Components/footer";
import {useLocation} from "react-router-dom";
import {Page111} from "./Program/111";
import {PageAlicia} from "./Program/alicia";
import {PageJK} from "./Program/jk";
import {PageLarmorVillage} from "./Program/larmorVillage";
import {PageLTL} from "./Program/ltl";
import {PageLTM} from "./Program/ltm";
import {PageLTP} from "./Program/ltp";
import {PageLTPH} from "./Program/ltph";
import {PageLTT} from "./Program/ltt";
import {PageQuelisoy} from "./Program/quelisoy";
import {PageTyCampus} from "./Program/ty_campus";
import {PageDK} from "./Program/dk";
import {PageLTPQ} from "./Program/ltpq";

export function Programs() {
    return (
        <div className={"w-full min-h-screen flex flex-col justify-between items-center gap-5 pt-10"}>
            <div className="flex flex-col items-start gap-4 pt-20 w-full">
                <TitleProjects/>
                <Projects/>
            </div>
            <Footer/>
        </div>
    );
}

export function Program() {
    const location = useLocation();

    const ValuesByProgram: Record<string, any> = {
        '/nos-programmes/111': <Page111/>,
        '/nos-programmes/alicia': <PageAlicia/>,
        '/nos-programmes/jk': <PageJK/>,
        '/nos-programmes/larmorvillage': <PageLarmorVillage/>,
        '/nos-programmes/ltl': <PageLTL/>,
        '/nos-programmes/ltm': <PageLTM/>,
        '/nos-programmes/ltp': <PageLTP/>,
        '/nos-programmes/ltph': <PageLTPH/>,
        '/nos-programmes/ltt': <PageLTT/>,
        '/nos-programmes/quelisoy': <PageQuelisoy/>,
        '/nos-programmes/ty-campus': <PageTyCampus/>,
        '/nos-programmes/dk': <PageDK/>,
        '/nos-programmes/ltpq': <PageLTPQ/>,
    };

    return ValuesByProgram[location.pathname];
}
